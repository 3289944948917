.latestNewsWrapper {
    margin-top: 21px !important;
}

.h2Title {
    /* font-size: 1.625rem; */
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 15px !important;
}

.liTag {
    margin-bottom: 14px !important;
    list-style-type: none;
}
.ulTag {
    padding-left: 0!important;
}
.newsLink {
    display: flex;
    margin-bottom: 14px;
}

.newsLink:hover {
    text-decoration: none;
}

.clockIcon {
    /* font-size: 0.9625rem; */
    font-size: 15.4px;
    margin-right: 10.5px !important;
    margin-top: 4px !important;
    color:#333;
}
.clockIcon:hover {
    color: #006998;
}

.newsLink:hover .clockIcon {
    color: #006998;
}

.timeStampText {
    /* font-size: 0.725rem; */
    font-size: 13px;
    color: #777;
    margin-bottom: 2px;
}

.h4Title {
    /* font-size: 0.785rem; */
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 4px !important;
}

.h4Title:hover {
    text-decoration: underline;
}

.bodyText {
    /* font-size: 0.725rem; */
    font-size: 13px;
    color: #333;
    margin-bottom: 4px !important;
}