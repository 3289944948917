.modalWrapper {
    padding: 14px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.h3Title {
    /* font-size: 1.25rem; */
    font-size: 20px;
    font-weight: bold;
    padding-top: 40px;
    padding-bottom: 25px;
}

.pb_39 {
    padding-bottom: 39px;
}

.btnStyle {
    /* font-size: 1rem !important; */
    font-size: 16.1px !important;
}