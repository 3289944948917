.commentsModalWrapper {
    padding: 10.5px 42px 21px;
}

.h1Title {
    /* font-size: 1.375rem; */
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 12.6px !important;
}

.cmtArea {
    margin-top: 7px !important;
}

.cmtTitle {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    padding-left: 14px;
    border: 2px;
    background: #cbcbcb;
    /* font-size: 0.8125rem; */
    font-size: 13px;
    font-weight: 700;
    padding-right: 10px;
}

.showingCommentsArea {
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}

.eachCmtWrapper {
    margin: 10.5px 0 !important;
    padding-top: 10.5px;
    padding-bottom: 3.5px;
    padding-left: 14px;
    padding-right: 14px;
    /* font-size: 0.75rem; */
    font-size: 12px;
    display: flex;
    flex-direction: column;
}

.eachCmtContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cmtInfo {
    display: flex;
    align-items: flex-end;
}

.userNameWrapper{
    font-weight: bold;
}

.timeStampText {
    color: #666666;
    font-weight: normal;
    margin-left: 7px !important;
}

.verticalLine {
    margin-left: 21px !important;
    padding-left: 21px;
    height: 20px;
    border-left: 2px solid;
    border-color: #333;
    font-weight: normal;
    display: inline;
}

.cmtText {
    margin: 7px 0 !important;
    padding-top: 3px;
    padding-bottom: 10.5px;
}

.inputStyle {
    /* font-size: 0.875rem; */
    font-size: 13px;
    color : #121212;
    width: 100%;
    height: 44px;
    margin-bottom: 5px !important;
    margin-top: 25px !important;
    border: 1px solid lightgray;
    padding-left: 11px;
}

.textAreaStyle {
    /* font-size: 0.875rem; */
    font-size: 13px;
    color: #121212;
    width: 100%;
    height: 76px;
    margin: 15px 0 !important;
}

.btnGroup {
    display: flex;
    justify-content: space-between;
}

.btnFontSize {
    /* font-size: 0.8125rem; */
    font-size: 13px;
}