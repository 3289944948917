.phen-card-app {
    color: #333;
    font-family: 'L10';
}

.phen-card-app p {
    margin-bottom: 1rem;
}

.phen-card-app .container,
.phen-card-app .container-fullwidth,
.phen-card-app .container-fullwidth {
    background-color: white;
    color: #333;
    min-width: unset;
    max-width: unset;
}

.phen-card-app a:not([href]):not([tabindex]) {
    color: unset;
}

.phen-card-app .inlineForThumbUpComponent {
    display: inline-block;
}

.phen-card-app .carousel-root .carousel-slider {
    overflow: unset;
}

.phen-card-app .carousel .control-dots .dot {
    background-color: #333;
}

.phen-card-app .carousel .control-dots {
    position: absolute;
    top: -36px;
    z-index: 999;
    text-align: right;
    pointer-events: none;
}

.phen-card-app .carousel-status {
    display: none;
}

.phen-card-app .carousel .slide {
    text-align: left;
    padding: 8px;
    cursor: pointer;
}

.phen-card-app .carousel .slide:hover a {
    cursor: pointer;
    text-decoration: none;
}

.phen-card-app .carousel .slide:hover h4 {
    text-decoration: underline;
    cursor: pointer;
    color: #333 !important;
}

.phen-card-app .carousel .slide:hover p {
    text-decoration: none;
}

.phen-card-app .mb_4 {
    margin-bottom: 10px
}

.phen-card-app .mb_5 {
    margin-bottom: 12.6px;
}

.phen-card-app .pr_4 {
    padding-right: 10px;
}

.phen-card-app .pb_4 {
    padding-bottom: 10px;
}

.phen-card-app .pt_4 {
    padding-top: 10px;
}

.phen-card-app .pb_2 {
    padding-bottom: 5px;
}

.phen-card-app .pr_3 {
    padding-right: 7px;
}

.phen-card-app .border-w-0_5 {
    border-width: 0.5px
}

.phen-card-app .w_h_16 {
    width: 16px;
    height: 16px;
}

.phen-card-app .p_4 {
    padding: 14px;
}

.phen-card-app input[type="text"], 
.phen-card-app textarea, .ReactModal__Content__Wrapper input[type="text"], .ReactModal__Content__Wrapper textarea {
    margin: 0;
    font-family: inherit;
    overflow: visible;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin-right: 15px;
    font-size: 16px;
    padding-left: 15px;
    border-radius: 1px;
    box-sizing: border-box;
    margin-bottom: 10px;
    z-index: 10;
    font-weight: 400;
    font-size: 1rem;
    display: block;
    width: 100%;
    height: 44px;
    padding: 0.875rem 0.875rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.429;
    color: #333;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #d5dbdf;
    border-radius: 0px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.phen-card-app .fp-wysiwyg, .ReactModal__Content__Wrapper .fp-wysiwyg {
    resize: both;
    overflow: auto;
    max-width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
}

.phen-card-app .btn:not([href]):not([tabindex]):hover,
.phen-card-app input:not([href]):not([tabindex]):hover[type="submit"],
.phen-card-app .form-submit:not([href]):not([tabindex]):hover,
.phen-card-app .btn:not([href]):not([tabindex]):focus,
.phen-card-app input:not([href]):not([tabindex]):focus[type="submit"],
.phen-card-app .form-submit:not([href]):not([tabindex]):focus,
.phen-card-app .btn:not([href]):not([tabindex]):active,
.phen-card-app input:not([href]):not([tabindex]):active[type="submit"],
.phen-card-app .form-submit:not([href]):not([tabindex]):active
/* .ReactModal__Content__Wrapper.btn:not([href]):not([tabindex]):hover,
.ReactModal__Content__Wrapper input:not([href]):not([tabindex]):hover[type="submit"],
.ReactModal__Content__Wrapper .form-submit:not([href]):not([tabindex]):hover,
.ReactModal__Content__Wrapper .btn:not([href]):not([tabindex]):focus,
.ReactModal__Content__Wrapper input:not([href]):not([tabindex]):focus[type="submit"],
.ReactModal__Content__Wrapper .form-submit:not([href]):not([tabindex]):focus,
.ReactModal__Content__Wrapper .btn:not([href]):not([tabindex]):active,
.ReactModal__Content__Wrapper input:not([href]):not([tabindex]):active[type="submit"],
.ReactModal__Content__Wrapper .form-submit:not([href]):not([tabindex]):active */
{
    color: #fff;
    cursor: pointer;
}

.phen-card-app .btn.btn-plain-red,
.phen-card-app input.btn-plain-red[type="submit"],
.phen-card-app .btn-plain-red.form-submit,
.phen-card-app .btn.btn-plain-red:not([href]):not([tabindex]),
.phen-card-app input.btn-plain-red:not([href]):not([tabindex])[type="submit"],
.phen-card-app .btn-plain-red.form-submit:not([href]):not([tabindex]), 
.ReactModal__Content__Wrapper .btn.btn-plain-red,
.ReactModal__Content__Wrapper input.btn-plain-red[type="submit"],
.ReactModal__Content__Wrapper .btn-plain-red.form-submit,
.ReactModal__Content__Wrapper .btn.btn-plain-red:not([href]):not([tabindex]),
.ReactModal__Content__Wrapper input.btn-plain-red:not([href]):not([tabindex])[type="submit"],
.ReactModal__Content__Wrapper .btn-plain-red.form-submit:not([href]):not([tabindex])
{
    color: #ff0d00;
    font-weight: 500;
    box-shadow: none;
}

.phen-card-app .btn.btn-plain-red:hover,
.phen-card-app input.btn-plain-red:hover[type="submit"],
.phen-card-app .btn-plain-red.form-submit:hover,
.phen-card-app .btn.btn-plain-red:focus,
.phen-card-app input.btn-plain-red:focus[type="submit"],
.phen-card-app .btn-plain-red.form-submit:focus,
.phen-card-app .btn.btn-plain-red:active,
.phen-card-app input.btn-plain-red:active[type="submit"],
.phen-card-app .btn-plain-red.form-submit:active,
.phen-card-app .btn.btn-plain-red:not([href]):not([tabindex]):hover,
.phen-card-app input.btn-plain-red:not([href]):not([tabindex]):hover[type="submit"],
.phen-card-app .btn-plain-red.form-submit:not([href]):not([tabindex]):hover,
.phen-card-app .btn.btn-plain-red:not([href]):not([tabindex]):focus,
.phen-card-app input.btn-plain-red:not([href]):not([tabindex]):focus[type="submit"],
.phen-card-app .btn-plain-red.form-submit:not([href]):not([tabindex]):focus,
.phen-card-app .btn.btn-plain-red:not([href]):not([tabindex]):active,
.phen-card-app input.btn-plain-red:not([href]):not([tabindex]):active[type="submit"],
.phen-card-app .btn-plain-red.form-submit:not([href]):not([tabindex]):active,
.ReactModal__Content__Wrapper .btn.btn-plain-red:hover,
.ReactModal__Content__Wrapper input.btn-plain-red:hover[type="submit"],
.ReactModal__Content__Wrapper .btn-plain-red.form-submit:hover,
.ReactModal__Content__Wrapper .btn.btn-plain-red:focus,
.ReactModal__Content__Wrapper input.btn-plain-red:focus[type="submit"],
.ReactModal__Content__Wrapper .btn-plain-red.form-submit:focus,
.ReactModal__Content__Wrapper .btn.btn-plain-red:active,
.ReactModal__Content__Wrapper input.btn-plain-red:active[type="submit"],
.ReactModal__Content__Wrapper .btn-plain-red.form-submit:active,
.ReactModal__Content__Wrapper .btn.btn-plain-red:not([href]):not([tabindex]):hover,
.ReactModal__Content__Wrapper input.btn-plain-red:not([href]):not([tabindex]):hover[type="submit"],
.ReactModal__Content__Wrapper .btn-plain-red.form-submit:not([href]):not([tabindex]):hover,
.ReactModal__Content__Wrapper .btn.btn-plain-red:not([href]):not([tabindex]):focus,
.ReactModal__Content__Wrapper input.btn-plain-red:not([href]):not([tabindex]):focus[type="submit"],
.ReactModal__Content__Wrapper .btn-plain-red.form-submit:not([href]):not([tabindex]):focus,
.ReactModal__Content__Wrapper .btn.btn-plain-red:not([href]):not([tabindex]):active,
.ReactModal__Content__Wrapper input.btn-plain-red:not([href]):not([tabindex]):active[type="submit"],
.ReactModal__Content__Wrapper .btn-plain-red.form-submit:not([href]):not([tabindex]):active
{
    color: #cc0a00;
}

.phen-card-app  .ql-container.ql-snow, .ReactModal__Content__Wrapper .ql-container.ql-snow {
    border: unset !important;
}

.phen-card-app .ql-toolbar.ql-snow, .ReactModal__Content__Wrapper  .ql-toolbar.ql-snow{
    border: unset !important;
    border-bottom: 1px solid #ccc !important;
}

.ReactModal__Content__Wrapper .btn-close-modal {
    z-index: 9999;
  }
.side-modal-left a {
    color: #006998 !important;
}
#card-body a {
    word-wrap: break-word;
}
/* .side-modal-content .carousel-slider{
    height: 120px;
} */
/* .side-modal-content .slider-wrapper {
    margin-top: 12px;
} */
.carousel-slider {
    position: unset !important;
    padding: 8px;
}
.control-dots {
    position: absolute !important;
    bottom: 0 !important;
    margin: 0px 0 !important;
    /* padding: 0; */
    text-align: center !important;
    width: 100% !important;
    z-index: 1 !important;
    padding: 8px !important;
    display: flex !important;
    justify-content: end !important;
    top: 7% !important;
}
.control-dots li {
    background: #333 !important;
}
.carousel-status{
    position: absolute;
    top: 28px !important;
    right: 0 !important;
    padding: 5px !important;
    font-size: 0.625rem;
    text-shadow:unset !important;
    color: #333 !important;
    font-weight: 700 !important;
}
.slider-wrapper h4 {
    color: #333 !important;
}
#card-body{
    /* font-size: 0.875rem; */
    font-size: 14px;
    margin-top: 1.3125rem;
}