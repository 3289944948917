.phen-card-app #card-body {
    margin-top: 20px;
}

.phen-card-app #card-body h2 {
    font-size: 0.527885rem;
    font-weight: 700;
    margin-bottom: 0.7875rem;
}

.phen-card-app #card-body h3 {
    font-size: 1.075rem;
    font-weight: 700;
    margin-bottom: 0.7875rem;
}

.phen-card-app #card-body p {
    font-size: 0.7875rem;
    margin-bottom: 0.875rem !important;
}

.phen-card-app #card-body ul {
    list-style-type: disc;
    padding-left: 25px;
    margin-bottom: 14px;
}

.phen-card-app #card-body li {
    font-size: 0.7875rem;
    margin-bottom: 7px;
}

.phen-card-app #card-body li a {
    color: #006998;
    overflow: hidden;
    overflow-wrap: break-word;
}

.phen-card-app #card-body li a:hover {
    text-decoration: underline;
}



