.ReactModal__Content__Wrapper_Comments {
    position: relative;
    background-color: #e8ebeb !important;
    max-width: none;
  }

.custom-quill {
    resize: both;
    overflow: auto;
    max-width: 100%;
    border: 1px solid #ccc;
}