.h1Title {
    margin-top: 5px !important;
    margin-bottom: 30px !important;
    /* font-size: 1.825rem; */
    font-size: 32px;
    font-weight: 700;
}

.cardHeaderWrapper {
    margin-bottom: 14px !important;
    display: flex;
    justify-content: space-between;
}

.headerWrapper {
    display: flex;
}

.phenTitle {
    margin-top: 4px !important;
    margin-left: 5px !important;
    /* font-size: 0.875rem; */
    font-size: 15px;
}

.verticalLine {
    margin: 8px 10.5px 0 !important;
    border-left: 2px solid #333;
    height: 14px;
}

.timeRange {
    /* font-size: 0.875rem; */
    font-size: 15px;
    margin-bottom: 2px !important;
}

.crowdsourced {
    /* font-size: 0.6625rem; */
    font-size: 12px;
    /* margin-bottom: 2px !important; */
}

.votingWrapper {
    width: 112px;
    display:flex;
    justify-content: center;
}

.summaryData {
    margin-bottom: 1.75rem !important;
    /* font-size: 0.875rem; */
    font-size: 15.4px;
    font-weight: 700;
}

.contentCardWrapper {
    margin-top: 3px;
}