.carouselWrapper {
   margin-top: 21px;
   margin-bottom: 21px;
   position: relative;
}

.h3Title {
    /* font-size: 1.1625rem; */
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.carouselBackground {
    background-color: #e8ebeb;
}

.textDesc {
    /* font-size: 0.725rem; */
    font-size: 13px;
    color: #777;
    margin-bottom: 1px !important;
}

.textDesc:hover {
    text-decoration: none;
}

.h4Title {
    font-weight: 500;
    /* font-size: 0.7875rem; */
    font-size: 14px;
    margin-bottom: 4px;
    color: #333;
}
.h4Title:hover {
    color: #006998;
}