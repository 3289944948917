.VotingLike_Wrapper {
  width: 43px;
  display: flex;
  align-items: center;
}
.likeAmount {
  margin-left: 4px;
}
.ThumbUp_Btn {
  color: #808080;
}

.ThumbUp_Btn:hover {
  color: #333;
}
.ThumbUp_Btn.active {
  color: #333;
}
