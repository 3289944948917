.videoIframeWrapper {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.iframeForVideo {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.mediaText {
    margin-top: 10px !important;
    font-size: 13px;
    font-style: italic;
}

.mediaImage {
    height: 100%;
    width: 100%;
    margin-top: 14px;
}