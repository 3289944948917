.linksWrapper {
    margin-top: 21px;
}

.h4Title {
    /* font-size: 0.875rem; */
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 14px;
}

.aTagLink {
    color: #006998;
    /* font-size: 0.7875rem; */
    font-size: 14px;
}