.h3Title {
    /* font-size: 1.375rem; */
    font-size: 22px;
    color: #121212;
    font-weight: 700;
    padding-bottom: 12px;
}

.textAreaStyle {
    /* font-size: 0.875rem; */
    font-size: 13px;
    color: #121212 !important;
    width: 100%;
    height: 360px;
    min-height: 360px;
}

.labelTag {
    /* font-size: 0.8125rem; */
    font-size: 13px;
    color: #333;
    margin-bottom: 0!important;
}

.modalFormActionsWrapper {
    display: flex !important;
    justify-content: space-between !important;
    padding-top: 0 !important;
}

.btnStyle {
    /* font-size: 1rem !important; */
    font-size: 16.1px !important;
    font-weight: 540;
}

.pl_0 {
    padding-left: 0;
} 